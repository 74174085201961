import { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { getPartList, getPart } from '../services/part.service';
import { errorHandler } from "../error";
import BaseSelectSearch from "./BaseSelectSearch";

export default function PartControl({readOnly, projectId = null, allowEmpty = false, onChange, value}) {
    const { instance } = useMsal();
    const [val, setVal] = useState(null);
    const [disabled, setDisabled] = useState(readOnly);

    useEffect(() => {
        if (projectId) {
            setDisabled(false);
        }
        else {
            setDisabled(true);
        }

        setVal(null);

        if (onChange) {
            onChange(null, { value: null, label: null });
        }

    }, [projectId]);

    useEffect(() => {
        async function fetchData() {
            try {
                let result;
                if (value) {
                    result = await getPart(instance, value);
                }

                if (result) {
                    if (onChange) {
                        onChange(result.id);
                    }

                    if (!val) {
                        setVal({
                            value: result.id,
                            label: result.name,
                        });
                    }
                }
            } catch (error) {
                errorHandler(error);
            }
        }
        fetchData();
    }, [value])

    return (
        <BaseSelectSearch
            disabled={disabled}
            promise={getPartList}
            valueField="id"
            labelField="name"
            value={val}
            filter={{ projectId, orderBy: [{ field: "name" }] }}
            placeholder="Välj Projektdel..."
            className="form-control p-0"
            onChange={(e) => {
                setVal(e)
                if (onChange) {
                    onChange(e.value);
                }
            }}
            emptyOption={allowEmpty}
            includeInput
            dark
        />
        /*
        <select className="form-select" value={value ?? ""} disabled={readOnly} onChange={(event) => {
            const val = event.target.value;
            setValue(val);
            if (onChange) {
                onChange(val ? val : null, { value: val ? val : null, name: null });
            }
        }}>
            {allowEmpty ? (<option value="">-- Välj projektdel --</option>) : null}
            {partList.length > 0 && (
                partList.map((part) => {
                    return (
                        <option key={part.id} value={part.id}>{part.name}</option>
                    );
                })
            )}
        </select>*/
    )
}
