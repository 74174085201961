import { useState, useEffect } from 'react'
import {
    useLoaderData,
    useNavigate,
    NavLink,
} from "react-router-dom";
import { getProject, createProject, updateProject, deleteProject } from '../../services/project.service';
import { Hdd, BoxArrowInLeft, Trash } from 'react-bootstrap-icons';
import { useMsal } from "@azure/msal-react";
import CardControl, { CardHeaderControl, CardBodyControl } from "../../components/CardControl";
import ToolbarControl, { ToolbarSeparatorControl } from "../../components/ToolbarControl";
import CustomerControl from "../../components/CustomerControl";
import ProjectFormatControl from "../../components/ProjectFormatControl";
import { errorHandler } from "../../error";
import { getFields } from "../../helpers/edit.helper";

export default function ProjectEdit() {
    const { project } = useLoaderData();
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [original, setOriginal] = useState({ ...project });
    const [entity, setEntity] = useState({ ...project });
    const [formatCode, setFormatCode] = useState(project.formatCode);

    //useEffect(() => {
    //    console.log(formatCode);
    //}, [formatCode]);

    //console.log({ ProjectEdit: true, original, project, entity });

    return (
        <CardControl className="border-0">
            <CardHeaderControl>
                <ToolbarControl>
                    <div className="btn-group" role="group">
                        <button className="btn" onClick={() => {
                            save(instance, entity, original).then(() => {
                                navigate(-1);
                            }).catch(errorHandler);
                        }}
                        >
                            <Hdd /> Spara
                        </button>
                        <button className="btn" onClick={() => {
                            navigate(-1);
                        }}
                        >
                            <BoxArrowInLeft /> Avbryt
                        </button>
                    </div>
                    {!original.id ? null : (
                        <>
                            <ToolbarSeparatorControl />
                            <div className="btn-group" role="group">
                                <button className="btn" onClick={() => {
                                    remove(instance, original).then((result) => {
                                        if (result) { navigate(-1); }
                                    }).catch(errorHandler);
                                }}
                                >
                                    <Trash /> Ta bort
                                </button>
                            </div>
                            <ToolbarSeparatorControl />
                            <div className="btn-group" role="group">
                                <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    Projektdel
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <NavLink className="dropdown-item" to={"../../part?projectId=" + original.id}>Visa projektdelar</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item" to={"../../part/create?projectId=" + original.id}>Skapa ny projektdel</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </>
                    )}
                </ToolbarControl>
            </CardHeaderControl>
            <CardBodyControl>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <NavLink title="Kund" to={"../../customer/" + original.customerId}>{original.customerName}</NavLink>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Projekt</li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col col-md-9 col-lg-6 mb-3">
                        <label className="form-label">Nummer</label>
                        <input type="text" className="form-control" readOnly={true} value={entity.number ?? ""} />
                    </div>
                    <div className="w-100 d-block"></div>
                    <div className="col col-md-9 col-lg-6 mb-3">
                        <label className="form-label">Namn</label>
                        <input type="text" className="form-control" maxLength="100" value={entity.name ?? ""} onChange={(e) => {
                            setEntity({ ...entity, name: e.target.value });
                        }} />
                    </div>
                    <div className="w-100 d-block"></div>
                    <div className="col col-md-9 col-lg-6 mb-3">
                        <label className="form-label">Kund</label>
                        <CustomerControl value={entity.customerId} onChange={(item) => {
                            setEntity({ ...entity, customerId: item });
                        }} />
                    </div>
                    <div className="w-100 d-block"></div>
                    <div className="col col-md-9 col-lg-6 mb-3">
                        <label className="form-label">Fakturaformat</label>
                        <ProjectFormatControl value={entity.formatId} onChange={(item) => {
                            setEntity({ ...entity, formatId: item.value });
                            if (item.data) {
                              setFormatCode(item.data.code);
                            }
                        }} />
                    </div>
                    <div className="w-100 d-block"></div>
                    {formatCode !== "groupby" ? null : (
                        <>
                            <div className="col col-md-6 col-lg-4">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="hasFormatProjectCheckBox" checked={entity.hasFormatProject ?? false} onChange={(e) => {
                                        setEntity({ ...entity, hasFormatProject: e.target.checked });
                                    }} />
                                    <label className="form-check-label" htmlFor="hasFormatProjectCheckBox">
                                        Projekt
                                    </label>
                                </div>
                            </div>
                            <div className="w-100 d-block"></div>
                            <div className="col col-md-6 col-lg-4 ms-4">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" disabled={!entity.hasFormatProject} id="hasFormatProjectNumberCheckBox" checked={entity.hasFormatProjectNumber ?? false} onChange={(e) => {
                                        setEntity({ ...entity, hasFormatProjectNumber: e.target.checked });
                                    }} />
                                    <label className="form-check-label" htmlFor="hasFormatProjectNumberCheckBox">
                                        Visa projektnummer
                                    </label>
                                </div>
                            </div>
                            <div className="w-100 d-block"></div>
                            <div className="col col-md-6 col-lg-4 ms-4">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" disabled={!entity.hasFormatProject} id="hasFormatProjectDescriptionCheckBox" checked={entity.hasFormatProjectDescription ?? false} onChange={(e) => {
                                        setEntity({ ...entity, hasFormatProjectDescription: e.target.checked });
                                    }} />
                                    <label className="form-check-label" htmlFor="hasFormatProjectDescriptionCheckBox">
                                        Visa projektbeskrivning
                                    </label>
                                </div>
                            </div>

                            <div className="w-100 d-block mb-3"></div>
                            <div className="col col-md-6 col-lg-4">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="hasFormatPartCheckBox" checked={entity.hasFormatPart ?? false} onChange={(e) => {
                                        setEntity({ ...entity, hasFormatPart: e.target.checked });
                                    }} />
                                    <label className="form-check-label" htmlFor="hasFormatPartCheckBox">
                                        Projektdel
                                    </label>
                                </div>
                            </div>
                            <div className="w-100 d-block"></div>
                            <div className="col col-md-6 col-lg-4 ms-4">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" disabled={!entity.hasFormatPart} id="hasFormatPartNumberCheckBox" checked={entity.hasFormatPartNumber ?? false} onChange={(e) => {
                                        setEntity({ ...entity, hasFormatPartNumber: e.target.checked });
                                    }} />
                                    <label className="form-check-label" htmlFor="hasFormatPartNumberCheckBox">
                                        Visa projektdelsnummer
                                    </label>
                                </div>
                            </div>
                            <div className="w-100 d-block"></div>
                            <div className="col col-md-6 col-lg-4 ms-4 mb-3">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" disabled={!entity.hasFormatPart} id="hasFormatPartDescriptionCheckBox" checked={entity.hasFormatPartDescription ?? false} onChange={(e) => {
                                        setEntity({ ...entity, hasFormatPartDescription: e.target.checked });
                                    }} />
                                    <label className="form-check-label" htmlFor="hasFormatPartDescriptionCheckBox">
                                        Visa projektdelsbeskrivning
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="hasFormatCategoryCheckBox" checked={entity.hasFormatCategory ?? false} onChange={(e) => {
                                        setEntity({ ...entity, hasFormatCategory: e.target.checked });
                                    }} />
                                    <label className="form-check-label" htmlFor="hasFormatCategoryCheckBox">
                                        Kategori
                                    </label>
                                </div>
                            </div>
                            <div className="ms-4 mb-4 w-25">
                                <label className="form-label">Nivå</label>
                                <input type="number" className="form-control" disabled={!entity.hasFormatCategory} value={entity.formatCategoryLevel ?? ""} onChange={(e) => {
                                    setEntity({ ...entity, formatCategoryLevel: e.target.value });
                                }} />
                            </div>

                            <div className="mb-3">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="hasFormatResourceCheckBox" checked={entity.hasFormatResource ?? false} onChange={(e) => {
                                        setEntity({ ...entity, hasFormatResource: e.target.checked });
                                    }} />
                                    <label className="form-check-label" htmlFor="hasFormatResourceCheckBox">
                                        Resurs
                                    </label>
                                </div>
                            </div>

                            <div className="col col-md-6 col-lg-4">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="hasFormatEveryCostCheckBox" checked={entity.hasFormatEveryCost ?? false} onChange={(e) => {
                                        setEntity({ ...entity, hasFormatEveryCost: e.target.checked });
                                    }} />
                                    <label className="form-check-label" htmlFor="hasFormatEveryCostCheckBox">
                                        Visa varje post
                                    </label>
                                </div>
                            </div>
                            <div className="w-100 d-block"></div>
                            <div className="col col-md-6 col-lg-4 ms-4">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" disabled={!entity.hasFormatEveryCost} id="hasFormatDescriptionDateCheckBox" checked={entity.hasFormatDescriptionDate ?? false} onChange={(e) => {
                                        setEntity({ ...entity, hasFormatDescriptionDate: e.target.checked });
                                    }} />
                                    <label className="form-check-label" htmlFor="hasFormatDescriptionDateCheckBox">
                                        Datum i beskrivningen
                                    </label>
                                </div>
                            </div>
                        </>
                    )}
                    {formatCode !== "fullspec" ? null : (
                        <>
                            <div className="col col-md-6 col-lg-4 mb-3">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="hasFormatDescriptionDateCheckBox" checked={entity.hasFormatDescriptionDate ?? false} onChange={(e) => {
                                        setEntity({ ...entity, hasFormatDescriptionDate: e.target.checked });
                                    }} />
                                    <label className="form-check-label" htmlFor="hasFormatDescriptionDateCheckBox">
                                        Datum i beskrivningen
                                    </label>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </CardBodyControl>
        </CardControl>
    );
}

export async function loader({ msalInstance, request }) {

    //console.log({ msalInstance: msalInstance, request: request });

    const project = await getProject(msalInstance, request.params.id);
    return { project };
}

export async function loaderCreate({ msalInstance, request }) {
    return { project: {} };
}

async function save(msalInstance, project, original) {

    //console.log({ msalInstance, project, original });
    let result;
    if (project.id) {
        let fields = getFields(project, original);
        result = await updateProject(msalInstance, project, fields);
    }
    else {
        result = await createProject(msalInstance, project);
    }

    //console.log({ result });
}

async function remove(msalInstance, original) {

    //console.log({ msalInstance, original });

    if (window.confirm("Är du säker på att du vill ta bort denna post?")) {
        await deleteProject(msalInstance, original.id);
        return true;
    }

    return false;
}

