import {useState, useEffect} from 'react';
import {useMsal} from "@azure/msal-react";
import {getUserList, getUser} from '../services/user.service';
import {errorHandler} from "../error";
import BaseSelectSearch from "./BaseSelectSearch";

export default function UserControl({value, readOnly = false, onChange}) {
    const {instance} = useMsal();
    const [val, setVal] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                let result = await getUser(instance, value ?? 'default');

                if (result) {
                    if (onChange) {
                        onChange(result.id);
                    }

                    if(!val) {
                        setVal({
                            value: result.id,
                            label: result.name,
                        });
                    }
                }
            } catch (error) {
                errorHandler(error);
            }
        }
        fetchData();
    }, [value])

    return (
        <BaseSelectSearch
            placeholder={"Välj resurs..."}
            promise={getUserList}
            value={val}
            labelField="name"
            valueField="id"
            disabled={readOnly}
            onChange={(item) => {
                setVal(item)

                if (onChange) {
                    onChange(item.value);
                }
            }}
            includeInput
            dark
        />
    )
}