import { useState, useEffect, useCallback, useRef } from 'react'
import {
    useLoaderData,
    useNavigate,
    NavLink,
} from "react-router-dom";
import { createInvoice } from '../../services/invoice.service';
import { Hdd, BoxArrowInLeft, ArrowLeft, ArrowRight } from 'react-bootstrap-icons';
import { useMsal } from "@azure/msal-react";
import CardControl, { CardHeaderControl, CardBodyControl } from "../../components/CardControl";
import ToolbarControl, { ToolbarSeparatorControl } from "../../components/ToolbarControl";
import { errorHandler } from "../../error";
import CustomerCreateInvoiceControl from '../../components/CustomerCreateInvoiceControl';
import PartCreateInvoiceControl from '../../components/PartCreateInvoiceControl';
import CategoryCreateInvoiceControl from '../../components/CategoryCreateInvoiceControl';
import CostCreateInvoiceControl from '../../components/CostCreateInvoiceControl';

export default function InvoiceCreate() {
    const { filter } = useLoaderData();
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [entity, setEntity] = useState({});
    const [from, setFrom] = useState(filter.from);
    const [to, setTo] = useState(filter.to);
    const [customerId, setCustomerId] = useState(null);
    const [step, setStep] = useState(filter.step);
    
    const [partId, setPartId] = useState([]);
    const [categoryId, setCategoryId] = useState([]);
    const [costId, setCostId] = useState([]);

    const lastStep = 4;

    useEffect(() => {
        setEntity({ costs: costId });
    }, [costId]);

    useEffect(() => {
        setEntity({ costs: costId });
    }, [costId]);

    useEffect(() => {
    }, [to, from]);

    //useEffect(() => {
    //    var f = { from, to, customerId };
    //    if (customerId) {
    //        getPartListCreateInvoice(instance, f).then((result) => {
    //            setPartList(result);
    //        }).catch(errorHandler);
    //    }
    //}, [from, to, customerId]);

    //useEffect(() => {
    //    var f = { from, to, parts: partId };
    //    if (partId && partId.length > 0) {
    //        getCategoryListCreateInvoice(instance, f).then((result) => {
    //            setCategoryList(result);
    //        }).catch(errorHandler);
    //    }
    //}, [from, to, partId]);

    const onPartSelectionChanged = useCallback((ids) => {
        setPartId(ids);
        setCategoryId([]);
    }, []);
    const onCategorySelectionChanged = useCallback((ids) => {
        setCategoryId(ids);
        setCostId([]);
    }, []);
    const onCostSelectionChanged = useCallback((ids) => {
        setCostId(ids);
    }, []);

    const nextStep = () => {
        switch (step) {
            case 1:
                if (!customerId) {
                    window.alert("Måste ange kund för att fortsätta.");
                    return;
                }
                break;
            case 2:
                if (!partId || partId.length === 0) {
                    window.alert("Måste ange projektdel för att fortsätta.");
                    return;
                }
                break;
            case 3:
                if (!categoryId || categoryId.length === 0) {
                    window.alert("Måste ange kategori för att fortsätta.");
                    return;
                }
                break;
            default:
                break;
        }

        setStep(step + 1);
    }

    const getStep = () => {
        switch (step) {
            case 1:
                return getStep1();
            case 2:
                return getStep2();
            case 3:
                return getStep3();
            case 4:
                return getStep4();
            default:
                return null;
        }
    }

    // Select Customer
    const getStep1 = () => {
        return (
            <div className="row">
                <div className="col col-sm-6 col-md-3 col-lg-2 col-12">
                    <label className="form-label">From</label>
                    <input type="date" className="form-control" defaultValue={from} onChange={(e) => {
                        setFrom(e.target.value);
                    }} />
                </div>
                <div className="col col-sm-6 col-md-3 col-lg-2 col-12">
                    <label className="form-label">Tom</label>
                    <input type="date" className="form-control" defaultValue={to} onChange={(e) => {
                        setTo(e.target.value);
                    }} />
                </div>
                <div className="w-100 d-block"></div>
                <div className="col col-sm-12 col-md-6 col-lg-4 col-12">
                    <label className="form-label">Kund</label>
                    <CustomerCreateInvoiceControl value={customerId} from={from} to={to} onChange={(e) => {
                        console.log(e);
                        setCustomerId(e);
                        setPartId([]);
                    }} />
                </div>
            </div>
        )
    };
    // Select Parts
    const getStep2 = () => {
        return (
            <>
                <PartCreateInvoiceControl from={from} to={to} customerId={customerId} partId={partId} onPartSelectionChanged={onPartSelectionChanged} />
            </>
        )
    };
    // Select Categories
    const getStep3 = () => {
        return (
            <>
                <CategoryCreateInvoiceControl from={from} to={to} partId={partId} categoryId={categoryId} onCategorySelectionChanged={onCategorySelectionChanged} />
            </>
        )
    };
    // Select Categories
    const getStep4 = () => {
        return (
            <>
                <CostCreateInvoiceControl from={from} to={to} categoryId={categoryId} costId={costId} onCostSelectionChanged={onCostSelectionChanged} />
            </>
        )
    };

    return (
        <CardControl className="border-0">
            <CardHeaderControl>
                <ToolbarControl>
                    <div className="btn-group" role="group">
                        {step < 2 ? null : (
                            <button className="btn" onClick={() => {
                                setStep(step - 1);
                            }}
                            >
                                <ArrowLeft /> Tillbaka
                            </button>
                        )}
                        {step >= lastStep ? null : (
                            <button className="btn" onClick={() => {
                                nextStep();
                            }}
                            >
                                <ArrowRight /> Nästa
                            </button>
                        )}
                        {step !== lastStep ? null : (
                            <button className="btn" onClick={() => {
                                save(instance, entity).then((invoice) => {
                                    navigate("/invoice/" + invoice.id);
                                }).catch(errorHandler);
                            }}
                            >
                                <Hdd /> Skapa faktura
                            </button>
                        )}
                        <ToolbarSeparatorControl />
                        <button className="btn" onClick={() => {
                            navigate("/invoice");
                        }}
                        >
                            <BoxArrowInLeft /> Avbryt
                        </button>
                    </div>
                </ToolbarControl>
            </CardHeaderControl>
            <CardBodyControl>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">Skapa faktura (Steg {step})</li>
                    </ol>
                </nav>
                {getStep()}
            </CardBodyControl>
        </CardControl>
    );
}

export async function loader({ msalInstance, request }) {
    let from = new URL(request.request.url).searchParams.get('fromDate') ?? null;
    let to = new URL(request.request.url).searchParams.get('toDate') ?? null;
    let customerId = new URL(request.request.url).searchParams.get('customerId') ?? null;
    let step = new URL(request.request.url).searchParams.get('step') ?? 1;

    if (!to) {
        var today = new Date();
        today.setMonth(today.getMonth() + 1, 1);
        today.setDate(0);
        to = today.toISOString().split('T')[0];
    }

    return { filter: { step, from, to, customerId } };
}

async function save(msalInstance, invoice) {

    //console.log({ msalInstance, invoice, original });
    let result = await createInvoice(msalInstance, invoice);
    return result;
}
