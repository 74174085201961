import { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { getPartStatusList, getPartStatus } from '../services/part-status.service';
import { errorHandler } from "../error";
import BaseSelectSearch from "./BaseSelectSearch";

//import { useSelect } from 'react-select-search';

export default function PartStatusControl({ readOnly = false, onChange, value }) {
    const { instance } = useMsal();
    const [val, setVal] = useState(null);
    const [disabled, setDisabled] = useState(readOnly);

    useEffect(() => {
        async function fetchData() {
            try {
                let result = await getPartStatus(instance, value ?? 'default');

                if (result) {
                    if (onChange) {
                        onChange(result.id);
                    }

                    if (!val) {
                        setVal({
                            value: result.id,
                            label: result.name,
                        });
                    }
                }
            } catch (error) {
                errorHandler(error);
            }
        }
        fetchData();
    }, [value])

    return (
        <BaseSelectSearch
            disabled={disabled}
            promise={getPartStatusList}
            labelField="name"
            valueField="id"
            value={val}
            filter={{ orderBy: [{ field: "sortOrder" }] }}
            onChange={(e) => {
                setVal(e);
                if (onChange) {
                    onChange(e.value);
                }
            }}
            dark
        />
        /*
        <SelectSearch options={options} value={value} disabled={readOnly}  placeholder="Välj status" onChange={(value, item) => {
            //console.log({ value, item });
            setValue(value);
            if (onChange) {
                onChange(value, item);
            }
        }} />*/
    )
}

// https://www.npmjs.com/package/react-select-search