import { torinConfig } from "../authConfig";
import { serializeQueryString } from "../helpers/url.helper";
//import { errorHandler } from "../error";

///////////////////////////////////
// Category BEGIN
///////////////////////////////////
export async function getCategory(accessToken, id, includes) {
    const options = getOptions("GET", accessToken);
    let queryString = "";
    if (includes) {
        queryString = "?" + serializeQueryString({ includes });
    }
    return fetch(torinConfig.torinApiEndpoint + 'category/' + id + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getCategoryList(accessToken, filter) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(filter);
    return fetch(torinConfig.torinApiEndpoint + 'category?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function createCategory(accessToken, entity) {
    const options = getOptions("POST", accessToken, entity);

    return fetch(torinConfig.torinApiEndpoint + 'category', options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function updateCategory(accessToken, entity, fields) {
    const options = getOptions("PUT", accessToken, entity);
    let queryString = serializeQueryString({ fields });
    return fetch(torinConfig.torinApiEndpoint + 'category?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function deleteCategory(accessToken, id) {
    const options = getOptions("DELETE", accessToken);

    return fetch(torinConfig.torinApiEndpoint + 'category/' + id, options)
        .then(responseHandler)
        .catch(errorHandler);
}
///////////////////////////////////
// Category END
///////////////////////////////////

///////////////////////////////////
// Cost BEGIN
///////////////////////////////////
export async function getCost(accessToken, id, includes) {
    const options = getOptions("GET", accessToken);
    let queryString = "";
    if (includes) {
        queryString = "?" + serializeQueryString({ includes });
    }
    return fetch(torinConfig.torinApiEndpoint + 'cost/' + id + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getCostWeekInfo(accessToken, filter) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(filter);
    return fetch(torinConfig.torinApiEndpoint + 'cost/weekinfo?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getCostWeek(accessToken, filter) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(filter);
    return fetch(torinConfig.torinApiEndpoint + 'cost/week?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getCostList(accessToken, filter) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(filter);
    return fetch(torinConfig.torinApiEndpoint + 'cost?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getCostSummaryList(accessToken, filter) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(filter);
    return fetch(torinConfig.torinApiEndpoint + 'cost/summary?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function createCost(accessToken, entity) {
    const options = getOptions("POST", accessToken, entity);

    return fetch(torinConfig.torinApiEndpoint + 'cost', options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function updateCost(accessToken, entity, fields) {
    const options = getOptions("PUT", accessToken, entity);
    let queryString = serializeQueryString({ fields });
    return fetch(torinConfig.torinApiEndpoint + 'cost?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function deleteCost(accessToken, id) {
    const options = getOptions("DELETE", accessToken);

    return fetch(torinConfig.torinApiEndpoint + 'cost/' + id, options)
        .then(responseHandler)
        .catch(errorHandler);
}
///////////////////////////////////
// Cost END
///////////////////////////////////

///////////////////////////////////
// CostType BEGIN
///////////////////////////////////
export async function getCostType(accessToken, id, includes) {
    const options = getOptions("GET", accessToken);
    let queryString = "";
    if (includes) {
        queryString = "?" + serializeQueryString({ includes });
    }
    return fetch(torinConfig.torinApiEndpoint + 'costType/' + id + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getCostTypeList(accessToken, filter) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(filter);
    return fetch(torinConfig.torinApiEndpoint + 'costType?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}
///////////////////////////////////
// CostType END
///////////////////////////////////

///////////////////////////////////
// Customer BEGIN
///////////////////////////////////
export async function getCustomer(accessToken, id, includes) {
    const options = getOptions("GET", accessToken);
    let queryString = "";
    if (includes) {
        queryString = "?" + serializeQueryString({ includes });
    }
    return fetch(torinConfig.torinApiEndpoint + 'customer/' + id + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getCustomerList(accessToken, filter) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(filter);
    return fetch(torinConfig.torinApiEndpoint + 'customer?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function createCustomer(accessToken, entity) {
    const options = getOptions("POST", accessToken, entity);

    return fetch(torinConfig.torinApiEndpoint + 'customer', options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function updateCustomer(accessToken, entity, fields) {
    const options = getOptions("PUT", accessToken, entity);
    let queryString = serializeQueryString({ fields });
    return fetch(torinConfig.torinApiEndpoint + 'customer?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function deleteCustomer(accessToken, id) {
    const options = getOptions("DELETE", accessToken);

    return fetch(torinConfig.torinApiEndpoint + 'customer/' + id, options)
        .then(responseHandler)
        .catch(errorHandler);
}
///////////////////////////////////
// Customer END
///////////////////////////////////

///////////////////////////////////
// Fortnox BEGIN
///////////////////////////////////

export async function refreshFortnoxAccessToken(accessToken, entity) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(entity);

    return fetch(torinConfig.torinApiEndpoint + 'fortnox?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function updateFortnoxCredentials(accessToken, entity) {
    const options = getOptions("POST", accessToken, entity);

    return fetch(torinConfig.torinApiEndpoint + 'fortnox/credentials', options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getFortnoxClientId(accessToken) {
    const options = getOptions("GET", accessToken);

    return fetch(torinConfig.torinApiEndpoint + 'fortnox/clientid', options)
        .then(responseHandler)
        .catch(errorHandler);
}


///////////////////////////////////
// Fortnox END
///////////////////////////////////

///////////////////////////////////
// Invoice BEGIN
///////////////////////////////////
export async function getInvoice(accessToken, id, includes) {
    const options = getOptions("GET", accessToken);
    let queryString = "";
    if (includes) {
        queryString = "?" + serializeQueryString({ includes });
    }
    return fetch(torinConfig.torinApiEndpoint + 'invoice/' + id + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getInvoiceList(accessToken, filter) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(filter);
    return fetch(torinConfig.torinApiEndpoint + 'invoice?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getInvoiceRowList(accessToken, id) {
    const options = getOptions("GET", accessToken);
    return fetch(torinConfig.torinApiEndpoint + 'invoice/' + id + '/row', options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function createInvoice(accessToken, entity) {
    const options = getOptions("POST", accessToken, entity);

    return fetch(torinConfig.torinApiEndpoint + 'invoice', options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function updateInvoice(accessToken, entity, fields) {
    const options = getOptions("PUT", accessToken, entity);
    let queryString = serializeQueryString({ fields });
    return fetch(torinConfig.torinApiEndpoint + 'invoice?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function deleteInvoice(accessToken, id) {
    const options = getOptions("DELETE", accessToken);

    return fetch(torinConfig.torinApiEndpoint + 'invoice/' + id, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function generateInvoiceRows(accessToken, id) {
    const options = getOptions("POST", accessToken);

    return fetch(torinConfig.torinApiEndpoint + 'invoice/' + id + '/generaterows', options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getCustomerListCreateInvoice(accessToken, filter) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(filter);
    return fetch(torinConfig.torinApiEndpoint + 'invoice/customer?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getPartListCreateInvoice(accessToken, filter) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(filter);
    return fetch(torinConfig.torinApiEndpoint + 'invoice/part?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getCategoryListCreateInvoice(accessToken, filter) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(filter);
    return fetch(torinConfig.torinApiEndpoint + 'invoice/category?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getCostListCreateInvoice(accessToken, filter) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(filter);
    return fetch(torinConfig.torinApiEndpoint + 'invoice/cost?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function sendInvoiceToFortnox(accessToken, id) {
    const options = getOptions("POST", accessToken);

    return fetch(torinConfig.torinApiEndpoint + 'invoice/send/' + id, options)
        .then(responseHandler)
        .catch(errorHandler);
}

///////////////////////////////////
// Invoice END
///////////////////////////////////


///////////////////////////////////
// Part BEGIN
///////////////////////////////////
export async function getPart(accessToken, id, includes) {
    const options = getOptions("GET", accessToken);
    let queryString = "";
    if (includes) {
        queryString = "?" + serializeQueryString({ includes });
    }
    return fetch(torinConfig.torinApiEndpoint + 'part/' + id + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getPartList(accessToken, filter) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(filter);
    return fetch(torinConfig.torinApiEndpoint + 'part?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function createPart(accessToken, entity) {
    const options = getOptions("POST", accessToken, entity);

    return fetch(torinConfig.torinApiEndpoint + 'part', options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function updatePart(accessToken, entity, fields) {
    const options = getOptions("PUT", accessToken, entity);
    let queryString = serializeQueryString({ fields });
    return fetch(torinConfig.torinApiEndpoint + 'part?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function deletePart(accessToken, id) {
    const options = getOptions("DELETE", accessToken);

    return fetch(torinConfig.torinApiEndpoint + 'part/' + id, options)
        .then(responseHandler)
        .catch(errorHandler);
}
///////////////////////////////////
// Part END
///////////////////////////////////

///////////////////////////////////
// PartStatus BEGIN
///////////////////////////////////
export async function getPartStatus(accessToken, id, includes) {
    const options = getOptions("GET", accessToken);
    let queryString = "";
    if (includes) {
        queryString = "?" + serializeQueryString({ includes });
    }
    return fetch(torinConfig.torinApiEndpoint + 'partstatus/' + id + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getPartStatusList(accessToken, filter) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(filter);
    return fetch(torinConfig.torinApiEndpoint + 'partstatus?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}
///////////////////////////////////
// PartStatus END
///////////////////////////////////

///////////////////////////////////
// Project BEGIN
///////////////////////////////////
export async function getProject(accessToken, id, includes) {
    const options = getOptions("GET", accessToken);
    let queryString = "";
    if (includes) {
        queryString = "?" + serializeQueryString({ includes });
    }
    return fetch(torinConfig.torinApiEndpoint + 'project/' + id + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getProjectList(accessToken, filter) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(filter);
    return fetch(torinConfig.torinApiEndpoint + 'project?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function createProject(accessToken, entity) {
    const options = getOptions("POST", accessToken, entity);

    return fetch(torinConfig.torinApiEndpoint + 'project', options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function updateProject(accessToken, entity, fields) {
    const options = getOptions("PUT", accessToken, entity);
    let queryString = serializeQueryString({ fields });
    return fetch(torinConfig.torinApiEndpoint + 'project?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function deleteProject(accessToken, id) {
    const options = getOptions("DELETE", accessToken);

    return fetch(torinConfig.torinApiEndpoint + 'project/' + id, options)
        .then(responseHandler)
        .catch(errorHandler);
}
///////////////////////////////////
// Project END
///////////////////////////////////

///////////////////////////////////
// ProjectFormat BEGIN
///////////////////////////////////
export async function getProjectFormat(accessToken, id, includes) {
    const options = getOptions("GET", accessToken);
    let queryString = "";
    if (includes) {
        queryString = "?" + serializeQueryString({ includes });
    }
    return fetch(torinConfig.torinApiEndpoint + 'projectformat/' + id + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getProjectFormatList(accessToken, filter) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(filter);
    return fetch(torinConfig.torinApiEndpoint + 'projectformat?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}
///////////////////////////////////
// ProjectFormat END
///////////////////////////////////

///////////////////////////////////
// Resource BEGIN
///////////////////////////////////
export async function getResource(accessToken, id, includes) {
    const options = getOptions("GET", accessToken);
    let queryString = "";
    if (includes) {
        queryString = "?" + serializeQueryString({ includes });
    }
    return fetch(torinConfig.torinApiEndpoint + 'resource/' + id + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getResourceList(accessToken, filter) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(filter);
    return fetch(torinConfig.torinApiEndpoint + 'resource?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function createResource(accessToken, entity) {
    const options = getOptions("POST", accessToken, entity);

    return fetch(torinConfig.torinApiEndpoint + 'resource', options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function updateResource(accessToken, entity, fields) {
    const options = getOptions("PUT", accessToken, entity);
    let queryString = serializeQueryString({ fields });
    return fetch(torinConfig.torinApiEndpoint + 'resource?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function deleteResource(accessToken, id) {
    const options = getOptions("DELETE", accessToken);

    return fetch(torinConfig.torinApiEndpoint + 'resource/' + id, options)
        .then(responseHandler)
        .catch(errorHandler);
}
///////////////////////////////////
// Resource END
///////////////////////////////////

///////////////////////////////////
// Unit BEGIN
///////////////////////////////////
export async function getUnit(accessToken, id, includes) {
    const options = getOptions("GET", accessToken);
    let queryString = "";
    if (includes) {
        queryString = "?" + serializeQueryString({ includes });
    }
    return fetch(torinConfig.torinApiEndpoint + 'unit/' + id + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getUnitList(accessToken, filter) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(filter);
    return fetch(torinConfig.torinApiEndpoint + 'unit?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}
///////////////////////////////////
// Unit END
///////////////////////////////////

///////////////////////////////////
// User BEGIN
///////////////////////////////////
export async function getUser(accessToken, id, includes) {
    const options = getOptions("GET", accessToken);
    let queryString = "";
    if (includes) {
        queryString = "?" + serializeQueryString({ includes });
    }
    return fetch(torinConfig.torinApiEndpoint + 'user/' + id + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function getUserList(accessToken, filter) {
    const options = getOptions("GET", accessToken);
    let queryString = serializeQueryString(filter);
    return fetch(torinConfig.torinApiEndpoint + 'user?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function createUser(accessToken, entity) {
    const options = getOptions("POST", accessToken, entity);

    return fetch(torinConfig.torinApiEndpoint + 'user', options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function updateUser(accessToken, entity, fields) {
    const options = getOptions("PUT", accessToken, entity);
    let queryString = serializeQueryString({ fields });
    return fetch(torinConfig.torinApiEndpoint + 'user?' + queryString, options)
        .then(responseHandler)
        .catch(errorHandler);
}

export async function deleteUser(accessToken, id) {
    const options = getOptions("DELETE", accessToken);

    return fetch(torinConfig.torinApiEndpoint + 'user/' + id, options)
        .then(responseHandler)
        .catch(errorHandler);
}
///////////////////////////////////
// User END
///////////////////////////////////

function responseHandler(response) {
    if (response.ok) {
        return response.json();
    }

    return errorHandler(response.text());
}

function errorHandler(response) {

    if (response instanceof Promise) {

        const prom = new Promise((resolve, reject) => {
            response.then((result) => {
                if (isJSON(result)) {
                    var json = JSON.parse(result);
                    console.log({ "text": "Repo errorHandler isJSON=true", json });
                    reject(new Error(json.message));
                    //throw new Error(json.clientMessage);
                }
                else {
                    console.log({ "text": "Repo errorHandler isJSON=false", result });
                    reject(new Error(result));
                }
            });
        });

        return prom;
    }

    console.log({ "text": "Repo errorHandler not Promise", response });
    throw response;
}

function getOptions(method, accessToken, body) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const json = body ? JSON.stringify(body) : null;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    const options = {
        method: method,
        headers: headers,
        body: json
    };
    return options;
}

//function serializeQueryString(obj, prefix, isArray) {
//    var str = [];
//    for (var param in obj) {
//        if (obj.hasOwnProperty(param)) {
//            var isParamNumber = !isNaN(param);
//            var value = obj[param];
//            //if (moment.isMoment(value)) {
//            //    value = value.isValid() ? value.format() : null;
//            //}
//            var paramValue = isParamNumber ? '[' + param + ']' : param;
//            var key = paramValue;
//            if (prefix) {
//                key = isArray ? prefix + '.' + paramValue : prefix + paramValue;
//            }
//            if (value !== null && value !== undefined) {
//                var qs = (typeof value === "object") ?
//                    serializeQueryString(value, key, isParamNumber) :
//                    encodeURIComponent(key) + "=" + encodeURIComponent(value);
//                if (qs) { str.push(qs); }
//            }
//        }
//    }
//    return str.join("&");
//}

function isJSON(text) {
    if (typeof text !== "string") {
        return false;
    }
    try {
        var json = JSON.parse(text);
        return (typeof json === 'object');
    }
    catch (error) {
        return false;
    }
}