import { useEffect, useState } from 'react';

export default function GroupByParentControl(props) {
    const [isGroupBy, setIsGroupBy] = useState(props.isGroupBy ?? false);
    const onGroupByChanged = props.onGroupByChanged;

    const handleCheckboxChange = (e) => {
        if (onGroupByChanged) {
            onGroupByChanged(e.target.checked);
        }
    };

    useEffect(() => {
        if ((props.isGroupBy ?? false) !== isGroupBy) {
            setIsGroupBy(props.isGroupBy ?? false);
        }
    }, [props])

    return (
        <div className="d-flex flex-column align-items-start w-100 my-2 mx-3">
            {props.label && <label className="form-label">{props.label}</label>}
            <div className="input-group mb-1 w-100">
                <div className="input-group-text">
                    <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        checked={isGroupBy}
                        onChange={handleCheckboxChange}
                        aria-label="Checkbox for following text input"
                    />
                </div>
                {props.children}
            </div>
        </div>
    );
}
