import { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { getResourceList, getResource } from '../services/resource.service';
import { errorHandler } from "../error";

export default function ResourceControl(props) {
    const { instance } = useMsal();
    const [value, setValue] = useState(props.value ?? "");
    const [readOnly, setReadOnly] = useState(props.readOnly ?? false);
    const [allowEmpty] = useState(props.allowEmpty ?? false);
    const onChange = props.onChange;
    const [resourceList, setResourceList] = useState([]);


    useEffect(() => {
        setReadOnly(props.readOnly ?? false);
        if (props.value !== undefined) {
            setValue(props.value ?? "");
        }
    }, [props]);

    useEffect(() => {
        async function fetchData() {
            const [resourceData] = await Promise.all([
                getResourceList(instance, { orderBy: [{ field: "name" }] })
            ]);

            setResourceList(resourceData);
        }
        fetchData();
    }, [instance]);

    useEffect(() => {
        if (!allowEmpty && !value) {
            getResource(instance, 'default').then((result) => {
                if (result) {
                    setValue(result.id);
                    if (onChange) {
                        onChange(result.id, { value: result.id, name: result.name });
                    }
                }
            }).catch((error) => {
                errorHandler(error);
            });
        }
    }, [instance, value, onChange]);

    return (
        <select className="form-select" value={value} disabled={readOnly} onChange={(event) => {
            const val = event.target.value;
            setValue(val);
            if (onChange) {
                onChange(val ? val : null, { value: val ? val : null, name: null });
            }
        }}>
            {allowEmpty ? (<option value="">-- Välj resurs --</option>) : null}
            {resourceList.length > 0 && (
                resourceList.map((resource) => {
                    return (
                        <option key={resource.id} value={resource.id}>{resource.name}</option>
                    );
                })
            )}
        </select>
    )
}