import React, { useState, useEffect, useRef } from 'react';
import {
    Outlet,
} from "react-router-dom";

import { PageLayout } from './components/PageLayout';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from "./authConfig";
import Nav from 'react-bootstrap/Nav';
import './App.css';

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */


/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
    let container = useRef(null);
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
            });
        }
    }

    // const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [appHeight, setAppHeight] = useState(null);
    useEffect(() => {
        const handleWindowResize = () => {
            setAppHeight(window.innerHeight - container.current.offsetTop);
        };

        window.addEventListener('resize', handleWindowResize);
        setAppHeight(window.innerHeight - container.current.offsetTop);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <div className="App" ref={container} style={{ height: appHeight }}>
            <AuthenticatedTemplate>
                <Outlet />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Nav.Link onClick={() => handleLogin("redirect")} className="btn btn-success bg-success m-2 p-3" style={{ maxWidth: '18rem' }} title="Logga in">
                    <div className="card-body">
                        <h5 className="card-title">Logga in</h5>
                        <p className="card-text">för att komma åt Torin</p>
                    </div>
                </Nav.Link>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default function App() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
