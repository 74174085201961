import { useState, useEffect, useCallback, useRef } from 'react';
import { useMsal } from "@azure/msal-react";
import { getCategoryListCreateInvoice } from '../services/invoice.service';
import { errorHandler } from "../error";
import BaseListControl, { currencyFormatter } from "./BaseListControl";

export default function CategoryCreateInvoiceControl(props) {
    const { instance } = useMsal();
    const [from, setFrom] = useState(props.from ?? null);
    const [to, setTo] = useState(props.to ?? null);
    const [partId, setPartId] = useState(props.partId ?? []);

    const categoryRef = useRef();
    const [categoryList, setCategoryList] = useState([]);
    const [categoryId, setCategoryId] = useState(props.categoryId ?? []);
    const onCategorySelectionChanged = props.onCategorySelectionChanged;

    const onSelectionChanged = useCallback((event) => {
        let ids = event.api.getSelectedRows().map((p) => p.id);
        setCategoryId(ids);
        onCategorySelectionChanged(ids);
    }, [onCategorySelectionChanged]);
    const onRowDataUpdated = useCallback((event) => {
        if (!categoryId || categoryId.length === 0) {
            event.api.selectAll();
            return;
        }

        const selected = [];
        const deselected = [];
        event.api.forEachNode((node) => {
            if (categoryId.some((x) => x === node.data.id)) {
                selected.push(node);
            }
            else {
                deselected.push(node);
            }
        });
        event.api.setNodesSelected({ nodes: selected, newValue: true });
        event.api.setNodesSelected({ nodes: deselected, newValue: false });
    }, [categoryId]);

    useEffect(() => {
        setPartId(props.partId ?? null);
        setFrom(props.from ?? null);
        setTo(props.to ?? null);
        setCategoryId(props.categoryId ?? []);
    }, [props]);

    useEffect(() => {
        var f = { from, to, parts: partId };
        if (partId && partId.length > 0) {
            getCategoryListCreateInvoice(instance, f).then((result) => {
                setCategoryList(result);
            }).catch(errorHandler);
        }
    }, [instance, from, to, partId]);

    return (
        <BaseListControl gridRef={categoryRef} columns={categoryColumnsDef} list={categoryList} rowSelection="multiple" onSelectionChanged={onSelectionChanged} onRowDataUpdated={onRowDataUpdated} tableName="SkapaFaktura3" />
    )
}

const categoryColumnsDef = [
    {
        headerName: "",
        checkboxSelection: true,
        width: 40,
        headerCheckboxSelectionFilteredOnly: false,
        headerCheckboxSelection: true,
    },
    {
        headerName: "Projektdelsnr",
        field: "partNumber",
        width: 150,
    },
    {
        headerName: "Projektdel",
        field: "partName",
        width: 400,
    },
    {
        headerName: "Kategori",
        field: "name",
        width: 400,
    },
    {
        headerName: "Belopp",
        field: "netAmount",
        valueFormatter: currencyFormatter,
        width: 130,
        type: ['numericColumn', 'rightAligned']
    },
    {
        headerName: "From",
        field: "fromDate",
        width: 110,
    },
    {
        headerName: "Tom",
        field: "toDate",
        width: 110,
    },
];


// https://www.ag-grid.com/react-data-grid/row-selection/
// https://www.ag-grid.com/react-data-grid/grid-options/