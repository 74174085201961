import {useState, useEffect, useRef} from 'react'
import {getCategoryList, getCategory} from '../services/category.service';
import {useMsal} from "@azure/msal-react";
import {TreeView} from '@mui/x-tree-view/TreeView';
import {TreeItem} from '@mui/x-tree-view/TreeItem';
import {errorHandler} from "../error";
import {Folder, Search, ChevronDown, ChevronRight} from 'react-bootstrap-icons';
import {SimpleTreeView} from "@mui/x-tree-view";
import {useNavigate} from "react-router-dom";

export default function CategoryControl(props) {
    const {instance} = useMsal();
    const [value, setValue] = useState(props.value ?? null);
    const [showInfo, setShowInfo] = useState(false);
    const [name, setName] = useState("");
    const [category, setCategory] = useState({});

    const [maxHours, setMaxHours] = useState(props.maxHours ?? 1);
    const [workedHours, setWorkedHours] = useState(props.workedHours ?? 0);

    const onChange = props.onChange;
    const navigate = useNavigate();

    useEffect(() => {
        if (value) {
            getCategory(instance, value).then((cat) => {
                let text = cat.projectName + ' / ' + cat.partName + ' / ' + cat.namePath;
                setCategory(cat)
                setName(text);
            }).catch(errorHandler);
        } else {
            setName("");
        }
    }, [value, instance]);

    useEffect(() => {
        setValue(props.value ?? null);
    }, [props]);
    return (
        <>
            <div className="input-group">
                <input type="text" className="form-control rounded-bottom-0" value={name} readOnly={true} onClick={() => {
                    setShowInfo(!showInfo)
                }}/>
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary rounded-bottom-0" type="button" data-bs-toggle="modal"
                            data-bs-target="#selectCategoryModal">
                        <Folder/>
                    </button>
                </div>
            </div>
            <CategoryModalControl value={value} onChange={(e) => {
                if (e) {
                    setValue(e);
                    onChange(e);
                }
            }}/>
            {!showInfo ? null : (
                <div className="card rounded-top-0">
                    <div className="card-header">
                        <span className="cursor-pointer" onClick={() => navigate(`/customer/${category.customerId}`)}>
                            {category.customerName}
                        </span>
                    </div>
                    <div className="card-header">
                        <span className="cursor-pointer" onClick={() => navigate(`/project/${category.projectId}`)}>
                            {category.projectName}
                        </span>
                    </div>
                    <div className="card-header">
                        <span className="cursor-pointer" onClick={() => navigate(`/part/${category.partId}`)}>
                            {category.partName}
                        </span>
                    </div>
                    <div className="card-header">
                        <span className="cursor-pointer" onClick={() => navigate(`/category/${category.id}`)}>
                            {category.namePath}
                        </span>
                    </div>
                    <div className="card-body">
                        <p>Min: 0</p>
                        <p>Max: {maxHours}</p>
                        <p>Antal timmar: {workedHours}</p>
                    </div>
                </div>

            )}
        </>
    )
}

export function CategoryModalControl(props) {
    const {instance} = useMsal();
    const [value, setValue] = useState(props.value ?? null);
    const [tree, setTree] = useState([]);
    const [text, setText] = useState("");
    const [expanded, setExpanded] = useState([]);
    const closeButtonRef = useRef(null);
    const onChange = (e) => {
        props.onChange(e);
        closeButtonRef.current.click();
    };

    useEffect(() => {
        setValue(props.value ?? null);
        search();
    }, [props]);

    const search = () => {
        const filter = {
            searchTerm: text,
            canRegisterCost: true,
            orderBy: [{ field: "partNamePath" }, { field: "NamePath" }]
        };

        //const expandList = new Set();
        let expandList = [];

        getCategoryList(instance, filter).then((result) => {
            if (!result || result.length === 0) {
                console.warn("No results found");
                setTree([]);
                setExpanded([]);
                return;
            }
            const list = [];
            const expandAll = result.length <= 10;

            result.forEach((item) => {
                // Create/find customer node
                let customer = list.find((x) => x.id === item.customerId);
                if (!customer) {
                    customer = {
                        id: item.customerId,
                        label: item.customerName,
                        childs: [],
                        expanded: true,
                        type: "cus"
                    };
                    list.push(customer);
                    if (expandAll) {
                        expandList.push(customer.id);
                    }
                }

                // Create/find project node
                let project = customer.childs.find((x) => x.id === item.projectId);
                if (!project) {
                    project = {
                        id: item.projectId,
                        label: item.projectName,
                        childs: [],
                        expanded: false,
                        type: "pro"
                    };
                    customer.childs.push(project);
                    if (expandAll) {
                        expandList.push(project.id);
                    }
                }

                // Create/find part node
                let part = project.childs.find((x) => x.id === item.partId);
                if (!part) {
                    part = {
                        id: item.partId,
                        label: item.partName,
                        childs: [],
                        expanded: false,
                        type: "par"
                    };
                    project.childs.push(part);
                    if (expandAll) {
                        expandList.push(part.id);
                    }
                }

                // Add category node
                const category = {
                    id: item.id,
                    label: item.namePath,
                    childs: [],
                    expanded: false,
                    type: "cat"
                };
                part.childs.push(category);
                if (expandAll) {
                    expandList.push(category.id);
                }

                if (!expandAll && category.id === value) {
                    expandList.push(category.id);
                    expandList.push(part.id);
                    expandList.push(project.id);
                    expandList.push(customer.id);
                }

                // Expand to match search text
                // expandToMatch(customer, text, expandList);
            });

            // Update expanded nodes and tree structure
            // setExpanded(Array.from(expandList).slice(0, 10)); // Limiting to first 10 for performance
            setExpanded(expandList);
            setTree(list);

            expandList = [];
        }).catch((error) => {
            console.error("Error fetching category list:", error);
        });
    };

    return (
        <div className="modal fade" tabIndex="-1" id="selectCategoryModal">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Välj kategori</h5>
                        <button type="button" ref={closeButtonRef} className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" value={text} placeholder="Sök" onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    search();
                                }
                            }} onChange={(e) => {
                                setText(e.target.value);
                            }}/>
                            <button className="btn btn-outline-secondary" type="button" onClick={search}>
                                <Search/>
                            </button>
                        </div>
                        <div className="w-100 d-block"></div>
                        <CategoryTreeControl list={tree} expanded={expanded} value={value} onChange={onChange}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function CategoryTreeControl(props) {
    const list = props.list;
    const [value, setValue] = useState(props.value);
    const [expanded, setExpanded] = useState(props.expanded);
    const onChange = (e) => {
        props.onChange(e);
    };

    useEffect(() => {
        setValue(props.value);
        setExpanded(props.expanded);
    }, [props]);

    const renderTree = (node) => (
        <TreeItem
            itemId={node.id}
            key={node.id}
            label={node.label}
            onClick={() => {
                if (node.type === "cat") {
                    onChange(node.id);
                }
            }}
        >
            {Array.isArray(node.childs) ? node.childs.map((x) => renderTree(x)) : null}
        </TreeItem>
    );

    return (
        <SimpleTreeView
            slots={{
                collapseIcon: ChevronDown,
                expandIcon: ChevronRight
            }}
            selectedItems={[value]}
            expandedItems={expanded}

            onExpandedItemsChange={(event, nodeIds) => {
                setExpanded(nodeIds);
            }}
            sx={{flexGrow: 1, overflowY: 'auto'}}
        >
            {list.map((x) => renderTree(x))}
        </SimpleTreeView>
    )
}