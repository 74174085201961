import { getAccessToken } from './base.service';
import {
    getProject as getProjectRepo,
    getProjectList as getProjectListRepo,
    createProject as createProjectRepo,
    updateProject as updateProjectRepo,
    deleteProject as deleteProjectRepo,
    getProjectFormat as getProjectFormatRepo,
    getProjectFormatList as getProjectFormatListRepo,
} from './../repositories/repository';

export async function getProject(msalInstance, id, includes) {

    //console.log({ msalInstance, id, includes });
    const accessToken = await getAccessToken(msalInstance);
    return await getProjectRepo(accessToken, id, includes);
}

export async function getProjectList(msalInstance, filter) {

    const accessToken = await getAccessToken(msalInstance);
    return await getProjectListRepo(accessToken, filter);
}

export async function createProject(msalInstance, project) {

    //console.log({ msalInstance, project });
    const accessToken = await getAccessToken(msalInstance);
    return await createProjectRepo(accessToken, project);
}

export async function updateProject(msalInstance, project, fields) {

    //console.log({ msalInstance, project, fields });
    const accessToken = await getAccessToken(msalInstance);
    return await updateProjectRepo(accessToken, project, fields);
}

export async function deleteProject(msalInstance, id) {

    //console.log({ msalInstance, id });
    const accessToken = await getAccessToken(msalInstance);
    return await deleteProjectRepo(accessToken, id);
}

export async function getProjectFormat(msalInstance, id, includes) {

    //console.log({ msalInstance, id, includes });
    const accessToken = await getAccessToken(msalInstance);
    return await getProjectFormatRepo(accessToken, id, includes);
}

export async function getProjectFormatList(msalInstance, filter) {

    //console.log({ msalInstance, filter });
    const accessToken = await getAccessToken(msalInstance);
    return await getProjectFormatListRepo(accessToken, filter);
}
