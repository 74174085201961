import { useState, useEffect, useCallback, useRef } from 'react';
import { useMsal } from "@azure/msal-react";
import { getCostListCreateInvoice } from '../services/invoice.service';
import { errorHandler } from "../error";
import BaseListControl, { currencyFormatter } from "./BaseListControl";

export default function CostCreateInvoiceControl(props) {
    const { instance } = useMsal();
    const [from, setFrom] = useState(props.from ?? null);
    const [to, setTo] = useState(props.to ?? null);
    const [categoryId, setCategoryId] = useState(props.categoryId ?? []);

    const costRef = useRef();
    const [costList, setCostList] = useState([]);
    const [costId, setCostId] = useState(props.costId ?? []);
    const onCostSelectionChanged = props.onCostSelectionChanged;

    const onSelectionChanged = useCallback((event) => {
        let ids = event.api.getSelectedRows().map((p) => p.id);
        setCostId(ids);
        onCostSelectionChanged(ids);
    }, [onCostSelectionChanged]);
    const onRowDataUpdated = useCallback((event) => {
        if (!costId || costId.length === 0) {
            event.api.selectAll();
            return;
        }

        const selected = [];
        const deselected = [];
        event.api.forEachNode((node) => {
            if (costId.some((x) => x === node.data.id)) {
                selected.push(node);
            }
            else {
                deselected.push(node);
            }
        });
        event.api.setNodesSelected({ nodes: selected, newValue: true });
        event.api.setNodesSelected({ nodes: deselected, newValue: false });
    }, [costId]);

    useEffect(() => {
        setCategoryId(props.categoryId ?? null);
        setFrom(props.from ?? null);
        setTo(props.to ?? null);
        setCostId(props.costId ?? []);
    }, [props]);

    useEffect(() => {
        var f = { from, to, categories: categoryId };
        if (categoryId && categoryId.length > 0) {
            getCostListCreateInvoice(instance, f).then((result) => {
                setCostList(result);
            }).catch(errorHandler);
        }
    }, [instance, from, to, categoryId]);

    return (
        <BaseListControl gridRef={costRef} columns={costColumnsDef} list={costList} rowSelection="multiple" onSelectionChanged={onSelectionChanged} onRowDataUpdated={onRowDataUpdated} tableName="SkapaFaktura4" />
    )
}

const costColumnsDef = [
    {
        headerName: "",
        checkboxSelection: true,
        width: 40,
        headerCheckboxSelectionFilteredOnly: false,
        headerCheckboxSelection: true,
    },
    {
        headerName: "Resurs",
        field: "resourceName",
        width: 150,
    },
    {
        headerName: "Datum",
        field: "date",
        width: 110,
    },
    {
        headerName: "Antal",
        field: "quantity",
        width: 90,
    },
    {
        headerName: "Enhet",
        field: "unitName",
        width: 100,
    },
    {
        headerName: "Enhetspris",
        field: "unitPrice",
        valueFormatter: currencyFormatter,
        width: 120,
        type: ['numericColumn', 'rightAligned']
    },
    {
        headerName: "Belopp",
        field: "netAmount",
        valueFormatter: currencyFormatter,
        width: 130,
        type: ['numericColumn', 'rightAligned']
    },
    {
        headerName: "Avvikelse",
        field: "isDeviation",
        width: 109,
    },
    {
        headerName: "Projektdelsnr",
        field: "partNumber",
        width: 135,
    },
    {
        headerName: "Projektdel",
        field: "partName",
        width: 400,
    },
    {
        headerName: "Kategori",
        field: "categoryName",
        width: 400,
    },
];


// https://www.ag-grid.com/react-data-grid/row-selection/
// https://www.ag-grid.com/react-data-grid/grid-options/