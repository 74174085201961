import { useState, useEffect } from 'react'
import {
    useLoaderData,
    useNavigate,
} from "react-router-dom";
import { getCustomer, createCustomer, updateCustomer, deleteCustomer } from '../../services/customer.service';
import { Hdd, BoxArrowInLeft, Trash } from 'react-bootstrap-icons';
import { useMsal } from "@azure/msal-react";
import CardControl, { CardHeaderControl, CardBodyControl } from "../../components/CardControl";
import ToolbarControl, { ToolbarSeparatorControl } from "../../components/ToolbarControl";
import { errorHandler } from "../../error";
import { getFields } from "../../helpers/edit.helper";
import UserControl from "../../components/UserControl";

export default function CustomerEdit() {
    const { customer } = useLoaderData();
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [original, setOriginal] = useState({ ...customer });
    const [entity, setEntity] = useState({ ...customer });

    const [isAddressEqual, setIsAddressEqual] = useState(customer.isVisitAndInvoiceAddressEqual ?? false);

    //console.log({ CustomerEdit: true, original, customer, isAddressEqual });

    useEffect(() => {
        setOriginal({ ...customer });
        setEntity({ ...customer });
    }, [customer]);

    return (
        <CardControl className="border-0">
            <CardHeaderControl>
                <ToolbarControl>
                    <div className="btn-group" role="group">
                        <button className="btn" onClick={() => {
                            save(instance, entity, original).then(() => {
                                navigate(-1);
                            }).catch(errorHandler);
                        }}
                        >
                            <Hdd /> Spara
                        </button>
                        <button className="btn" onClick={() => {
                            navigate(-1);
                        }}
                        >
                            <BoxArrowInLeft /> Avbryt
                        </button>
                    </div>
                    {!original.id ? (<></>) : (
                        <>
                            <ToolbarSeparatorControl />
                            <div className="btn-group" role="group">
                                <button className="btn" onClick={() => {
                                    remove(instance, original).then((result) => {
                                        if (result) { navigate(-1); }
                                    }).catch(errorHandler);
                                }}
                                >
                                    <Trash /> Ta bort
                                </button>
                            </div>
                        </>
                    )}
                </ToolbarControl>
            </CardHeaderControl>
            <CardBodyControl>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">Kund</li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col col-md-3 col-lg-2 mb-3">
                        <label className="form-label">Nummer</label>
                        <input type="text" className="form-control" readOnly={true} value={entity.number ?? ""} />
                    </div>
                    <div className="col col-md-6 col-lg-4 mb-3">
                        <label className="form-label">Alias</label>
                        <input type="text" className="form-control" maxLength="50" value={entity.alias ?? ""} onChange={(e) => {
                            setEntity({ ...entity, alias: e.target.value });
                        }} />
                    </div>
                    <div className="w-100 d-block"></div>
                    <div className="col col-md-9 col-lg-6 mb-3">
                        <label className="form-label">Namn</label>
                        <input type="text" className="form-control" maxLength="100" value={entity.name ?? ""} onChange={(e) => {
                            setEntity({ ...entity, name: e.target.value });
                        }} />
                    </div>

                    <div className="w-100 d-block"></div>
                    <div className="col col-md-9 col-lg-6 mb-3">
                        <label className="form-label">Kundansvarig</label>
                        <UserControl value={entity.accountManagerUserId} onChange={(userId) => {
                            setEntity({ ...entity, accountManagerUserId: userId });
                        }} />
                    </div>
                    <div className="w-100 d-block"></div>
                    <div className="col col-md-4 col-lg-3 mb-3">
                        <label className="form-label">Betalningsvillkor</label>
                        <input type="number" className="form-control" value={entity.paymentTerm ?? 0} onChange={(e) => {
                            setEntity({ ...entity, paymentTerm: e.target.value });
                        }} />
                    </div>
                    <div className="col col-md-5 col-lg-3 mb-3">
                        <label className="form-label">Enhetspris (standard)</label>
                        <input type="number" className="form-control" value={entity.defaultUnitPrice ?? ""} onChange={(e) => {
                            setEntity({ ...entity, defaultUnitPrice: e.target.value ?? null });
                        }} />
                    </div>


                    <div className="w-100 d-block"></div>
                    <div className="col col-md-9 col-lg-6 mb-3">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="isVisitAndInvoiceAddressEqual" checked={entity.isVisitAndInvoiceAddressEqual} onChange={(e) => {
                                setEntity({ ...entity, isVisitAndInvoiceAddressEqual: e.target.checked });
                                setIsAddressEqual(e.target.checked);
                            }} />
                            <label className="form-check-label" htmlFor="isVisitAndInvoiceAddressEqual">
                                Besöksadress och fakturadress är lika
                            </label>
                        </div>
                    </div>
                    <div className="w-100 d-block"></div>
                    <div className="col col-md-9 col-lg-6 mb-3">
                        <CardControl className="border-1">
                            <CardHeaderControl>{isAddressEqual ? ("Besöks och fakturerings-adress") : ("Besöksadress")}</CardHeaderControl>
                            <CardBodyControl>
                                <div className="row">
                                    <div className="col col-12">
                                        <label className="form-label">Adress</label>
                                        <input type="text" className="form-control" maxLength="100" value={entity.visitAddress ?? ""} onChange={(e) => {
                                            setEntity({ ...entity, visitAddress: e.target.value });
                                            //customer.visitAddress = e.target.value;
                                        }} />
                                        <input type="text" className="form-control" maxLength="100" value={entity.visitAddress1 ?? ""} onChange={(e) => {
                                            setEntity({ ...entity, visitAddress1: e.target.value });
                                            //customer.visitAddress1 = e.target.value;
                                        }} />
                                        <input type="text" className="form-control" maxLength="100" value={entity.visitAddress2 ?? ""} onChange={(e) => {
                                            setEntity({ ...entity, visitAddress2: e.target.value });
                                            //customer.visitAddress2 = e.target.value;
                                        }} />
                                        <input type="text" className="form-control" maxLength="100" value={entity.visitAddress3 ?? ""} onChange={(e) => {
                                            setEntity({ ...entity, visitAddress3: e.target.value });
                                            //customer.visitAddress3 = e.target.value;
                                        }} />
                                    </div>
                                    <div className="col col-4">
                                        <label className="form-label">Postnummer</label>
                                        <input type="text" className="form-control" maxLength="20" value={entity.visitZipCode ?? ""} onChange={(e) => {
                                            setEntity({ ...entity, visitZipCode: e.target.value });
                                            //customer.visitZipCode = e.target.value;
                                        }} />
                                    </div>
                                    <div className="col col-8">
                                        <label className="form-label">Postort</label>
                                        <input type="text" className="form-control" maxLength="50" value={entity.visitCity ?? ""} onChange={(e) => {
                                            setEntity({ ...entity, visitCity: e.target.value });
                                            //customer.visitCity = e.target.value;
                                        }} />
                                    </div>
                                </div>
                            </CardBodyControl>
                        </CardControl>
                    </div>
                    <div className="w-100 d-block"></div>
                    <div className="col col-md-9 col-lg-6 mb-3">
                        {isAddressEqual ? (<></>) : (
                            <CardControl>
                                <CardHeaderControl>Faktureringsadress</CardHeaderControl>
                                <CardBodyControl>
                                    <div className="row">
                                        <div className="col col-12">
                                            <label className="form-label">Adress</label>
                                            <input type="text" className="form-control" maxLength="100" value={entity.invoiceAddress ?? ""} onChange={(e) => {
                                                setEntity({ ...entity, invoiceAddress: e.target.value });
                                                //customer.invoiceAddress = e.target.value;
                                            }} />
                                            <input type="text" className="form-control" maxLength="100" value={entity.invoiceAddress1 ?? ""} onChange={(e) => {
                                                setEntity({ ...entity, invoiceAddress1: e.target.value });
                                                //customer.invoiceAddress1 = e.target.value;
                                            }} />
                                            <input type="text" className="form-control" maxLength="100" value={entity.invoiceAddress2 ?? ""} onChange={(e) => {
                                                setEntity({ ...entity, invoiceAddress2: e.target.value });
                                                //customer.invoiceAddress2 = e.target.value;
                                            }} />
                                            <input type="text" className="form-control" maxLength="100" value={entity.invoiceAddress3 ?? ""} onChange={(e) => {
                                                setEntity({ ...entity, invoiceAddress3: e.target.value });
                                                //customer.invoiceAddress3 = e.target.value;
                                            }} />
                                        </div>
                                        <div className="col col-4">
                                            <label className="form-label">Postnummer</label>
                                            <input type="text" className="form-control" maxLength="20" value={entity.invoiceZipCode ?? ""} onChange={(e) => {
                                                setEntity({ ...entity, invoiceZipCode: e.target.value });
                                                //customer.invoiceZipCode = e.target.value;
                                            }} />
                                        </div>
                                        <div className="col col-8">
                                            <label className="form-label">Postort</label>
                                            <input type="text" className="form-control" maxLength="50" value={entity.invoiceCity ?? ""} onChange={(e) => {
                                                setEntity({ ...entity, invoiceCity: e.target.value });
                                                //customer.invoiceCity = e.target.value;
                                            }} />
                                        </div>
                                    </div>
                                </CardBodyControl>
                            </CardControl>
                        )}
                    </div>
                </div>
            </CardBodyControl>
        </CardControl>
    );
}

export async function loader({ msalInstance, request }) {

    //console.log({ msalInstance: msalInstance, request: request });

    const customer = await getCustomer(msalInstance, request.params.id);
    return { customer };
}

export async function loaderCreate({ msalInstance, request }) {
    return { customer: {} };
}

async function save(msalInstance, customer, original) {

    //console.log({ msalInstance, customer, original });
    let result;
    if (customer.id) {
        let fields = getFields(customer, original);
        result = await updateCustomer(msalInstance, customer, fields);
    }
    else {
        result = await createCustomer(msalInstance, customer);
    }

    //console.log({ result });
}

async function remove(msalInstance, original) {

    //console.log({ msalInstance, original });

    if (window.confirm("Är du säker på att du vill ta bort denna post?")) {
        await deleteCustomer(msalInstance, original.id);
        return true;
    }

    return false;
}
