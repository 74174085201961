import { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { getCategoryList, getCategory } from '../services/category.service';
import { errorHandler } from "../error";
import BaseSelectSearch from "./BaseSelectSearch";

export default function CategorySelectControl({partId, allowEmpty, readOnly, onChange, value}) {
    const { instance } = useMsal();
    const [val, setVal] = useState(null);
    const [disabled, setDisabled] = useState(readOnly);
    useEffect(() => {
        async function fetchData() {
            try {
                let result;
                if (value) {
                    result = await getCategory(instance, value);
                }

                if (result) {
                    if (onChange) {
                        onChange(result.id);
                    }

                    if (!val) {
                        setVal({
                            value: result.id,
                            label: result.namePath,
                        });
                    }
                }
            } catch (error) {
                errorHandler(error);
            }
        }
        fetchData();
    }, [value])

    useEffect(() => {

        if (partId) {
            setDisabled(false);
        }
        else {
            setDisabled(true);
        }

        setVal(null);
        if (onChange) {
            onChange(null);
        }

    }, [partId]);

    return (
        <BaseSelectSearch
            disabled={disabled}
            promise={getCategoryList}
            valueField="id"
            labelField="namePath"
            value={val}
            filter={{ partId, isCanceled: false, orderBy: [{ field: "namePath" }] }}
            placeholder="Välj Kategori..."
            className="form-control p-0"
            onChange={(e) => {
                setVal(e)
                if (onChange) {
                    onChange(e.value);
                }
            }}
            emptyOption={allowEmpty}
            includeInput
            dark
        />
        /*
        <select className="form-select" value={value ?? ""} disabled={readOnly} onChange={(event) => {
            const val = event.target.value;
            setValue(val);
            if (onChange) {
                onChange(val ? val : null, { value: val ? val : null, name: null });
            }
        }}>
            {allowEmpty ? (<option value="">-- Välj kategori --</option>) : null}
            {categoryList.length > 0 && (
                categoryList.map((category) => {
                    return (
                        <option key={category.id} value={category.id}>{category.name}</option>
                    );
                })
            )}
        </select>*/
    )
}
