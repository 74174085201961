import AsyncSelect from "react-select/async";
import {useMsal} from "@azure/msal-react";
import { useEffect, useState } from "react";

export default function BaseSelectSearch({ includeInput, filter, promise, value, valueField, labelField, placeholder = "Välj...", onChange, className, dark, defaultValue, disabled, emptyOption }) {
    const { instance } = useMsal();
    const [val, setVal] = useState(value ?? null);
    // const [loadOptions, setLoadOptions] = useState(() => (inputValue) => Promise.resolve([]));
     
    const loadOptions = (inputValue) => new Promise(async (resolve) => {
        const filters = includeInput ? { ...(filter || {}), searchTerm: inputValue } : { ...(filter || {}) };
        let choices = [];

        if (emptyOption) {
            choices.push({
                value: "",
                label: placeholder,
            });
        }

        const fetchedChoices = (await promise(instance, filters))
            .map((item) => ({
                value: item[valueField],
                label: item[labelField],
                data: item
            }));

        choices = [...choices, ...fetchedChoices];

        resolve(choices);
    });

    useEffect(() => {
            
    }, [value]);

    //useEffect(() => {
    //    console.log(loadOptions());
    //}, [loadOptions])

    //useEffect((inputValue) => {
    //    setLoadOptions(() => () => new Promise(async (resolve) => {
    //        const filters = includeInput ? { ...(filter || {}), searchTerm: inputValue } : { ...(filter || {}) };
    //        console.log({ name: "BaseSelectSearch", filters });
    //        let choices = [];

    //        if (emptyOption) {
    //            choices.push({
    //                value: "",
    //                label: placeholder,
    //            });
    //        }

    //        const fetchedChoices = (await promise(instance, filters))
    //            .map((item) => ({
    //                value: item[valueField],
    //                label: item[labelField],
    //            }));

    //        choices = [...choices, ...fetchedChoices];

    //        resolve(choices);
    //    }));
    //}, [includeInput, filter, emptyOption, placeholder, instance, valueField, labelField, promise]);

    //useEffect(() => {
    //    setLoadOptions(() => options);
    //}, [options]);

    const darkTheme = {
        control: (provided, { isDisabled }) => ({
            ...provided,
            backgroundColor: isDisabled ? "#343a40" : '#212529', // Control background
            borderColor: '#555', // Border color
            color: isDisabled ? '#aaa' : "#fff", // Font color
            //boxShadow: 'none', // Remove shadow
            '&:hover': {
                borderColor: '#777' // Border color on hover
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isDisabled ? "#343a40" : state.isFocused ? '#282C31' : '#1F2328', // Background on focus
            color: state.isFocused ? '#fff' : '#ccc', // Font color on focus
            '&:active': {
                backgroundColor: '#555', // Background when active
                color: '#fff' // Font color when active
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#212529', // Menu background
            color: '#fff' // Menu font color
        }),
        menuList: (provided) => ({
            ...provided,
            backgroundColor: '#212529', // Menu list background
            color: '#fff', // Menu list font color
            border: "1px solid #666"
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#fff' // Selected value color
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#ccc' // Placeholder color
        }),
        input: (provided) => ({
            ...provided,
            color: '#fff' // Input text color
        }),
        // Optional: Style for the clear indicator (if applicable)
        clearIndicator: (provided) => ({
            ...provided,
            color: '#fff' // Clear indicator color
        }),
        // Optional: Style for the dropdown indicator (the arrow)
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#fff' // Dropdown indicator color
        })
    };

    return (
        <AsyncSelect
            key={JSON.stringify(filter)}
            className={`text-dark ${className || ""}`}
            loadOptions={loadOptions}
            placeholder={placeholder}
            noOptionsMessage={() => "Inga alternativ"}
            loadingMessage={() => "Laddar..."}
            onChange={onChange}
            styles={dark ? darkTheme : undefined}
            defaultValue={() => {
                if (defaultValue) {
                    return defaultValue;
                } else if (emptyOption) {
                    return {
                        value: "",
                        label: placeholder,
                    };
                }
            }}
            value={value}
            defaultOptions
            isDisabled={disabled}
            cacheOptions={false}
        />
    );
}
