import { useState, useEffect } from 'react'
import {
    useLoaderData,
    useNavigate,
    NavLink,
} from "react-router-dom";
import { getCost, createCost, updateCost, deleteCost } from '../../services/cost.service';
import { getCategory } from '../../services/category.service';
import { Hdd, BoxArrowInLeft, Trash, Copy } from 'react-bootstrap-icons';
import { useMsal } from "@azure/msal-react";
import CardControl, { CardHeaderControl, CardBodyControl } from "../../components/CardControl";
import ToolbarControl, { ToolbarSeparatorControl } from "../../components/ToolbarControl";
import { errorHandler } from "../../error";
import { getFields } from "../../helpers/edit.helper";
import ResourceControl from "../../components/ResourceControl";
import CategoryControl from '../../components/CategoryControl';

export default function CostEdit() {
    const { cost } = useLoaderData();
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [original, setOriginal] = useState({ ...cost });
    const [entity, setEntity] = useState({ ...cost });
    const [categoryId, setCategoryId] = useState(cost.categoryId ?? null);
    const [category, setCategory] = useState({});
    const [isLocked, setIsLocked] = useState(cost.isLocked ?? false);

    const [maxHours, setMaxHours] = useState(10);
    const [workedHours, setWorkedHours] = useState(11);

    const workPercentage = (workedHours / Math.max(maxHours, 0.000001)) * 100;

    // Determine widths for each section
    const greenWidth = Math.min(workPercentage, 66);
    const yellowWidth = Math.min(Math.max(workPercentage - 66, 0), 34); // Caps yellow to the remaining space up to 100%
    const redWidth = Math.max(workPercentage - 100, 0); // Only fills if it exceeds 100%

    //console.log({ CostEdit: true, original, cost, entity, category });

    useEffect(() => {
        //console.log("UPDATE original and entity");
        setOriginal({ ...cost });
        setEntity({ ...cost });
        setCategoryId(cost.categoryId ?? null);
        setIsLocked(cost.isLocked ?? false);
    }, [cost]);

    useEffect(() => {
        if (categoryId) {
            getCategory(instance, categoryId).then((result) => {
                setCategory(result);
                let e = { ...entity };
                let isChanged = false;
                if (result.isUnitLocked && !entity.isLocked) {
                    e.unitId = result.unitId;
                    isChanged = true;
                }
                if (result.isUnitPriceLocked && !entity.isLocked) {
                    e.unitPrice = result.unitPrice;
                    isChanged = true;
                }
                if (result.isCostTypeLocked && !entity.isLocked) {
                    e.costTypeId = result.costTypeId;
                    isChanged = true;
                }

                setMaxHours(result.quantity);

                if (isChanged) setEntity(e);
                //console.log({ category: result, entity: e });
            }).catch((error) => {
                errorHandler(error);
            });
        }
        else {
            setCategory({});
        }
    }, [categoryId]);

    return (
        <CardControl className="border-0">
            <CardHeaderControl>
                <ToolbarControl>
                    <div className="btn-group" role="group">
                        <button className="btn" onClick={() => {
                            save(instance, entity, original).then(() => {
                                navigate("/cost");
                            }).catch(errorHandler);
                        }}
                        >
                            <Hdd /> Spara
                        </button>
                        <button className="btn" onClick={() => {
                            navigate("/cost");
                        }}
                        >
                            <BoxArrowInLeft /> Avbryt
                        </button>
                    </div>
                    {!original.id ? null : (
                        <>
                            <ToolbarSeparatorControl />
                            <div className="btn-group" role="group">
                                <button className="btn" onClick={() => {
                                    remove(instance, original).then((result) => {
                                        if (result) { navigate("/cost"); }
                                    }).catch(errorHandler);
                                }}
                                >
                                    <Trash /> Ta bort
                                </button>
                            </div>
                        </>
                    )}
                    <ToolbarSeparatorControl />
                    <div className="btn-group" role="group">
                        <button className="btn" onClick={() => {
                            copy(instance, entity, original).then((ori) => {
                                setEntity(ori);
                                setOriginal(ori);
                            }).catch(errorHandler);
                        }}
                        >
                            <Copy /> Kopiera
                        </button>
                    </div>
                </ToolbarControl>
            </CardHeaderControl>
            <CardBodyControl>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">Rapportering</li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col col-md-3 col-lg-2 mb-3">
                        <label className="form-label">Datum</label>
                        <input type="date" className="form-control" value={entity.date ?? ""} readOnly={entity.isLocked}
                               onChange={(e) => {
                                   setEntity({...entity, date: e.target.value});
                               }}/>
                    </div>

                    <div className="col col-md-6 col-lg-4 mb-3">
                        <label className="form-label">Resurs</label>
                        <ResourceControl value={entity.resourceId} readOnly={isLocked} onChange={(resourceId) => {
                            setEntity({...entity, resourceId: resourceId});
                        }}/>
                    </div>

                    <div className="w-100 d-block"></div>
                    <div className="col col-md-9 col-lg-6 mb-3">
                        <label className="form-label">Kategori</label>
                        <CategoryControl value={entity.categoryId} readOnly={isLocked} onChange={(categoryId) => {
                            if (categoryId) {
                                setEntity({...entity, categoryId: categoryId});
                                setCategoryId(categoryId);
                            }
                        }}/>
                    </div>

                    <div className="w-100 d-block"></div>
                    <div className="col col-md-3 col-lg-2 mb-3">
                        <label className="form-label">Antal</label>
                        <input type="number" className="form-control" value={entity.quantity ?? ""}
                               readOnly={entity.isLocked} onChange={(e) => {
                            setEntity({...entity, quantity: e.target.value});
                        }}/>
                    </div>

                    <div className="col col-md-3 col-lg-2 mb-3">
                        <label className="form-label">Enhet</label>
                        <input type="text" className="form-control" value={entity.unitName ?? category.unitName ?? ""}
                               readOnly={true}/>
                    </div>

                    <div className="col col-md-3 col-lg-2 mb-3">
                        <label className="form-label">Enhetspris</label>
                        <input type="number" className="form-control" value={entity.unitPrice ?? ""}
                               readOnly={entity.isLocked || category.isUnitPriceLocked} onChange={(e) => {
                            setEntity({...entity, unitPrice: e.target.value});
                        }}/>
                    </div>

                    <div className="w-100 d-block"></div>
                    <div className="col col-md-9 col-lg-6 mb-3">
                        <label className="form-label">Beskrivning</label>
                        <textarea className="form-control" value={entity.description} readOnly={entity.isLocked}
                                  rows="4" onChange={(e) => {
                            setEntity({...entity, description: e.target.value});
                        }}/>
                    </div>

                    <div className="w-100 d-block"></div>
                    <div className="col col-md-3 col-lg-2 mb-3">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" disabled={entity.isLocked} value=""
                                   id="deviationCheckBox" checked={entity.isDeviation ?? false} onChange={(e) => {
                                setEntity({...entity, isDeviation: e.target.checked});
                            }}/>
                            <label className="form-check-label" htmlFor="deviationCheckBox">
                                Avvikelse
                            </label>
                        </div>
                    </div>
                    <div className="w-100 d-block"></div>

                    { !entity.categoryId ? null : (
                    <div className="progress-stacked col col-md-9 col-lg-6 mb-3 p-0" style={{marginLeft: "12px", marginRight: "12px"}}>
                        <div className="progress" role="progressbar" aria-label="Segment one"
                             aria-valuenow={greenWidth}
                             aria-valuemin="0" aria-valuemax="100" style={{width: `${greenWidth}%`}}>
                            <div className="progress-bar bg-success"></div>
                        </div>
                        <div className="progress" role="progressbar" aria-label="Segment two"
                             aria-valuenow={yellowWidth}
                             aria-valuemin="0" aria-valuemax="100" style={{width: `${yellowWidth}%`}}>
                            <div className="progress-bar bg-warning"></div>
                        </div>
                        <div className="progress" role="progressbar" aria-label="Segment three"
                             aria-valuenow={redWidth}
                             aria-valuemin="0" aria-valuemax="100" style={{width: `${redWidth}%`}}>
                            <div className="progress-bar bg-danger"></div>
                        </div>
                    </div>
                    )}
                </div>
            </CardBodyControl>
        </CardControl>
    );
}

export async function loader({msalInstance, request}) {

    //console.log({ msalInstance: msalInstance, request: request });

    const cost = await getCost(msalInstance, request.params.id);
    return {cost};
}

export async function loaderCreate({msalInstance, request}) {
    const categoryId = new URL(request.request.url).searchParams.get('categoryId') ?? null;
    const resourceId = new URL(request.request.url).searchParams.get('resourceId') ?? null;
    const date = new URL(request.request.url).searchParams.get('date') ?? new Date().toISOString().split('T')[0];

    return {cost: {categoryId, resourceId, date, isLocked: false}};
}

async function save(msalInstance, cost, original) {

    //console.log({ msalInstance, cost, original });
    let result;
    if (cost.id) {
        let fields = getFields(cost, original);
        result = await updateCost(msalInstance, cost, fields);
    } else {
        result = await createCost(msalInstance, cost);
    }

    return result;
}

async function remove(msalInstance, original) {

    //console.log({ msalInstance, original });

    if (window.confirm("Är du säker på att du vill ta bort denna post?")) {
        await deleteCost(msalInstance, original.id);
        return true;
    }

    return false;
}

async function copy(msalInstance, cost, original) {
    let result = { ...cost };

    if (!original.isLocked) {
        result = await save(msalInstance, cost, original);
    }

    result.id = undefined;
    result.isLocked = false;
    result.invoiceId = null;
    let date = new Date(result.date);
    date.setDate(date.getDate() + 1);
    result.date = date.toISOString().split("T")[0];
    return result;
}
