import { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { getProjectFormatList, getProjectFormat } from '../services/project.service';
import { errorHandler } from "../error";
import BaseSelectSearch from "./BaseSelectSearch";

export default function ProjectFormatControl({ readOnly = false, onChange, value }) {
    const { instance } = useMsal();
    const [val, setVal] = useState(null);
    const [disabled, setDisabled] = useState(readOnly);

    useEffect(() => {
        async function fetchData() {
            try {
                let result;
                if (value) {
                    result = await getProjectFormat(instance, value);
                }

                if (result) {
                    if (onChange) {
                        onChange(result.id);
                    }

                    if (!val) {
                        setVal({
                            value: result.id,
                            label: result.name,
                            data: result,
                        });
                    }
                }
            } catch (error) {
                errorHandler(error);
            }
        }
        fetchData();
    }, [value])

    return (
        <BaseSelectSearch
            disabled={disabled}
            promise={getProjectFormatList}
            valueField="id"
            labelField="name"
            value={val}
            filter={{ orderBy: [{ field: "sortOrder" }] }}
            onChange={(e) => {
                setVal(e);
                if (onChange) {
                    onChange(e.value);
                }
            }}
            dark
        />

        /*<SelectSearch options={options} value={value} disabled={readOnly}  placeholder="Välj format" onChange={(value, item) => {
            //console.log({ value, item });
            setValue(value);
            if (onChange) {
                onChange(value, item);
            }
        }} />*/
    )
}