import { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { getCustomerListCreateInvoice as getCustomerList } from '../services/invoice.service';
import { getCustomer } from '../services/customer.service';
import { errorHandler } from "../error";
import BaseSelectSearch from "./BaseSelectSearch";

export default function CustomerCreateInvoiceControl({ value, from, to, onChange }) {
    const { instance } = useMsal();
    const [val, setVal] = useState(null);
    const [fromValue, setFromValue] = useState(from ?? null);
    const [toValue, setToValue] = useState(to ?? null);

    useEffect(() => {
        // setVal(value ?? null);
        setFromValue(from ?? null);
        setToValue(to ?? null);
    }, [from, to]);

    useEffect(() => {
        async function fetchData() {
            try {
                let result;
                if (value) {
                    result = await getCustomer(instance, value);
                }

                if (result) {
                    if (onChange) {
                        onChange(result.id);
                    }

                    if (!val) {
                        setVal({
                            value: result.id,
                            label: result.name,
                        });
                    }
                }
            } catch (error) {
                errorHandler(error);
            }
        }
        fetchData();
    }, [value])


    return (
        <BaseSelectSearch
            filter={{ from: fromValue, to: toValue }}
            promise={getCustomerList}
            valueField="id"
            labelField="name"
            value={val}
            placeholder="Välj kund..."
            defaultValue={value}
            onChange={(e) => {
                setVal(e);
                if (onChange) {
                    onChange(e.value);
                }
            }}
            emptyOption={false}
            includeInput
            dark
        />

        /*<SelectSearch options={options} value={value} placeholder="Välj kund" onChange={(value, item) => {
            //console.log({ value, item });
            setValue(value);
            if (props.onChange) {
                props.onChange(value, item);
            }
        }} />*/
    )
}

// https://www.npmjs.com/package/react-select-search