import { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { getInvoiceList, getInvoice } from "../services/invoice.service";
import { errorHandler } from "../error";
import BaseSelectSearch from "./BaseSelectSearch";

export default function InvoiceControl({ customerId, allowEmpty, readOnly, onChange, value }) {
    const { instance } = useMsal();
    const [val, setVal] = useState(null);
    const [disabled, setDisabled] = useState(readOnly);
    useEffect(() => {

        setVal(null);
        if (onChange) {
            onChange(null);
        }

    }, [customerId]);

    useEffect(() => {
        async function fetchData() {
            try {
                let result;
                if (value) {
                    result = await getInvoice(instance, value);
                }

                if (result) {
                    if (onChange) {
                        onChange(result.id);
                    }

                    if (!val) {
                        setVal({
                            value: result.id,
                            label: result.number,
                        });
                    }
                }
            } catch (error) {
                errorHandler(error);
            }
        }
        fetchData();
    }, [value])

    return (
        <BaseSelectSearch
            disabled={disabled}
            promise={getInvoiceList}
            valueField="id"
            labelField="number"
            value={val}
            filter={{ customerId, orderBy: [{ field: "number" }] }}
            placeholder="Välj Faktura..."
            className="form-control p-0"
            onChange={(e) => {
                setVal(e)
                if (onChange) {
                    onChange(e.value);
                }
            }}
            emptyOption={allowEmpty}
            includeInput
            dark
        />
    )
}