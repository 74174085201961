import React, { useState, useEffect, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { getInvoiceRowList } from '../services/invoice.service';
import { errorHandler } from "../error";
import BaseListControl, { currencyFormatter, percentFormatter } from './BaseListControl'

const columns = [
    {
        headerName: "Artikel",
        field: "articleNumber",
        filter: false,
        width: 80,
    },
    {
        headerName: "Beskrivning",
        field: "description",
        filter: false,
        width: 600,
        cellStyle: params => {
            var indent = params.data.indent;
            if (indent > 0) {
                return {paddingLeft: indent+1+"rem" };
            }
        }
    },
    {
        headerName: "Antal",
        field: "quantity",
        filter: false,
        width: 80,
        type: ['numericColumn', 'rightAligned']
    },
    {
        headerName: "Enhet",
        field: "unitName",
        filter: false,
        width: 80,
    },
    {
        headerName: "Enhetspris",
        field: "unitPrice",
        valueFormatter: currencyFormatter,
        filter: false,
        width: 130,
        type: ['numericColumn', 'rightAligned']
    },
    {
        headerName: "Belopp",
        field: "netAmount",
        valueFormatter: currencyFormatter,
        filter: false,
        width: 130,
        type: ['numericColumn', 'rightAligned']
    },
    {
        headerName: "Moms",
        field: "vatAmount",
        valueFormatter: currencyFormatter,
        filter: false,
        width: 130,
        type: ['numericColumn', 'rightAligned']
    },
    {
        headerName: "Moms (%)",
        field: "vatPercent",
        valueFormatter: percentFormatter,
        filter: false,
        width: 130,
        type: ['numericColumn', 'rightAligned']
    },
]

export default function InvoiceRowListControl({ invoiceId, update }) {
    const { instance } = useMsal();
    const [invoiceRowList, setInvoiceRowList] = useState([]);

    useEffect(() => {
        if (invoiceId) {
            getInvoiceRowList(instance, invoiceId).then((result) => {
                setInvoiceRowList(result);
            }).catch(errorHandler);
        }
        else {
            setInvoiceRowList([]);
        }
    }, [invoiceId, update]);

    return (
        <BaseListControl columns={columns} list={invoiceRowList} tableName="Fakturarader" />
    );
}


//function indentFormatter(params) {
//    if (!params.value) {
//        return;
//    }
//    var indent = params.data.indent;
//    let text = ".".repeat(indent * 4);
//    // params.node.level = indent;
//    return text + params.value;
//}
