import { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { getUnitList } from '../services/unit.service';

export default function UnitControl(props) {
    const { instance } = useMsal();
    const [value, setValue] = useState(props.value ?? "");
    //const [options, setOptions] = useState([]);
    const [allowEmpty] = useState(props.allowEmpty ?? false);
    const [readOnly, setReadOnly] = useState(props.readOnly ?? false);
    const onChange = props.onChange;
    const [unitList, setUnitList] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const [data] = await Promise.all([
                getUnitList(instance, { orderBy: [{ field: "sortOrder" }] })
            ]);
            if (!value && !allowEmpty) {
                let defaultUnit = data.find((x) => (x.isDefault));
                if (defaultUnit) {
                    setValue(defaultUnit.id);
                    if (onChange) {
                        onChange(defaultUnit.id, defaultUnit);
                    }
                }
            }
            setUnitList(data);
        }
        fetchData();
    //}, [allowEmpty, instance, onChange]);
    }, [allowEmpty, instance]);

    useEffect(() => {
        setReadOnly(props.readOnly ?? false);
        if (props.value !== undefined) {
            setValue(props.value ?? "");
        }
    }, [props]);

    return (
        <select className="form-select" value={value} disabled={readOnly} onChange={(event) => {
            const val = event.target.value;
            setValue(val);
            if (onChange) {
                onChange(val ? val : null, { value: val ? val : null, name: null });
            }
        }}>
            {allowEmpty ? (<option value="">-- Välj enhet --</option>) : null}
            {unitList.length > 0 && (
                unitList.map((unit) => {
                    return (
                        <option key={unit.id} value={unit.id}>{unit.name}</option>
                    );
                })
            )}
        </select>
    )
}