import { useEffect, useState } from 'react';

export default function BooleanControl(props) {
    const [value, setValue] = useState(props.value ?? "");
    const [readOnly, setReadOnly] = useState(props.readOnly ?? false);
    const onChange = props.onChange;

    useEffect(() => {
        setReadOnly(props.readOnly ?? false);
        if (props.value !== undefined) {
            if (props.value === true) {
                setValue("1");
            }
            else if (props.value === false) {
                setValue("2");
            }
            else {
                setValue("");
            }
        }
    }, [props]);

    return (

        <select className="form-select" value={value} disabled={readOnly} onChange={(event) => {
            const val = event.target.value ? event.target.value : null;
            setValue(val);
            if (onChange) {
                switch (val) {
                    case "2":
                        console.log("onChange: false");
                        onChange(false);
                        break;
                    case "1":
                        console.log("onChange: true");
                        onChange(true);
                        break;
                    default:
                        console.log("onChange: null: " + val);
                        onChange(null);
                        break;
                }
            }
        }}>
            <option>Alla</option>
            <option value={1}>Ja</option>
            <option value={2}>Nej</option>
        </select>
    )
}