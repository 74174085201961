import { useState, useEffect } from 'react'
import {
    useLoaderData,
    useNavigate,
    NavLink,
} from "react-router-dom";
import { getPart, createPart, updatePart, deletePart } from '../../services/part.service';
import { getProject } from '../../services/project.service';
import { Hdd, BoxArrowInLeft, Trash } from 'react-bootstrap-icons';
import { useMsal } from "@azure/msal-react";
import CardControl, { CardHeaderControl, CardBodyControl } from "../../components/CardControl";
import ToolbarControl, { ToolbarSeparatorControl } from "../../components/ToolbarControl";
import { errorHandler } from "../../error";
import { getFields } from "../../helpers/edit.helper";
import PartStatusControl from '../../components/PartStatusControl';

export default function PartEdit() {

    const { part, project } = useLoaderData();
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [original, setOriginal] = useState({ ...part });
    const [entity, setEntity] = useState({ ...part });

    //console.log({ PartEdit: true, original, part, entity });

    useEffect(() => {
        setOriginal({ ...part });
        setEntity({ ...part });
    }, [part]);

    return (
        <CardControl className="border-0">
            <CardHeaderControl>
                <ToolbarControl>
                    <div className="btn-group" role="group">
                        <button className="btn" onClick={() => {
                            save(instance, entity, original).then(() => {
                                navigate(-1);
                            }).catch(errorHandler);
                        }}
                        >
                            <Hdd /> Spara
                        </button>
                        <button className="btn" onClick={() => {
                            navigate(-1);
                        }}
                        >
                            <BoxArrowInLeft /> Avbryt
                        </button>
                    </div>
                    {!original.id ? (<></>) : (
                        <>
                            <ToolbarSeparatorControl />
                            <div className="btn-group" role="group">
                                <button className="btn" onClick={() => {
                                    remove(instance, original).then((result) => {
                                        if (result) { navigate(-1); }
                                    }).catch(errorHandler);
                                }}
                                >
                                    <Trash /> Ta bort
                                </button>
                            </div>
                            <ToolbarSeparatorControl />
                            <div className="btn-group" role="group">
                                <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    Kategori
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <NavLink className="dropdown-item" to={"../../category?partId=" + original.id}>Visa kategorier</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item" to={"../../category/create?partId=" + original.id}>Skapa ny kategori</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </>
                    )}
                </ToolbarControl>
            </CardHeaderControl>
            <CardBodyControl>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <NavLink title="Kund" to={"../../customer/" + project.customerId}>{project.customerName}</NavLink>
                        </li>
                        <li className="breadcrumb-item">
                            <NavLink title="Projekt" to={"../../project/" + project.id}>{project.name}</NavLink>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Projektdel</li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col col-md-9 col-lg-6 mb-3">
                        <label className="form-label">Nummer</label>
                        <input type="text" className="form-control" readOnly={true} value={entity.number ?? ""} />
                    </div>
                    <div className="w-100 d-block"></div>
                    <div className="col col-md-9 col-lg-6 mb-3">
                        <label className="form-label">Namn</label>
                        <input type="text" className="form-control" maxLength="100" value={entity.name} onChange={(e) => {
                            setEntity({ ...entity, name: e.target.value });
                            //part.name = e.target.value;
                        }} />
                    </div>
                    <div className="w-100 d-block"></div>
                    <div className="col col-md-9 col-lg-6 mb-3">
                        <label className="form-label">Status</label>
                        <PartStatusControl value={entity.partStatusId} onChange={(partStatusId) => {
                            setEntity({ ...entity, partStatusId });
                            //part.partStatusId = partStatusId;
                        }} />
                    </div>
                </div>
            </CardBodyControl>
        </CardControl>
    );
}

export async function loader({ msalInstance, request }) {

    //console.log({ msalInstance: msalInstance, request: request });

    const part = await getPart(msalInstance, request.params.id, ["Project"]);
    return { part, project: part.project };
}

export async function loaderCreate({ msalInstance, request }) {
    const projectId = new URL(request.request.url).searchParams.get('projectId');
    const project = await getProject(msalInstance, projectId);
    return { part: { projectId }, project };
}

async function save(msalInstance, part, original) {

    //console.log({ msalInstance, part, original });
    let result;
    if (part.id) {
        let fields = getFields(part, original);
        result = await updatePart(msalInstance, part, fields);
    }
    else {
        result = await createPart(msalInstance, part);
    }

    //console.log({ result });
}

async function remove(msalInstance, original) {

    //console.log({ msalInstance, original });

    if (window.confirm("Är du säker på att du vill ta bort denna post?")) {
        await deletePart(msalInstance, original.id);
        return true;
    }

    return false;
}

