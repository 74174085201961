import { useIsAuthenticated } from '@azure/msal-react';
import { SignInButton } from './SignInButton';
import { SignOutButton } from './SignOutButton';
import {
    NavLink,
} from "react-router-dom";

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props
 */
export const PageLayout = (props) => {
    const isAuthenticated = useIsAuthenticated();

    return (
        <>
            <nav data-bs-theme="light" className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <NavLink to="/" className="navbar-brand">Torin</NavLink>
                    <button className="navbar-toggler bg-dark" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Växla navigering">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        {isAuthenticated ? (
                            <>
                                <ul className="navbar-nav me-auto">
                                    <li className="nav-item">
                                        <NavLink to="customer" className="nav-link">Kunder</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="cost" className="nav-link">Tidsredovisning</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="project" className="nav-link">Projekt</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="invoice" className="nav-link">Fakturering</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="cost/summary" className="nav-link">Uppföljning</NavLink>
                                    </li>

                                {/*    <li className="nav-item dropdown">*/}
                                {/*        <button className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">*/}
                                {/*            Uppdrag*/}
                                {/*        </button>*/}
                                {/*        <ul className="dropdown-menu">*/}
                                {/*            <li><NavLink to="project" className="dropdown-item">Projekt</NavLink></li>*/}
                                {/*            <li><NavLink to="part" className="dropdown-item">Projektdelar</NavLink></li>*/}
                                {/*            <li><NavLink to="category" className="dropdown-item">Kategorier</NavLink></li>*/}
                                {/*        </ul>*/}
                                {/*    </li>*/}
                                </ul>
                            </>
                        ) : null}
                        <div className="navbar-nav d-flex">
                            {isAuthenticated ? <SignOutButton /> : <SignInButton />}                            
                        </div>
                    </div>
                {/*    <Navbar.Collapse className="justify-content-end">*/}
                {/*        <Nav className="pr-2">*/}
                {/*        </Nav>*/}
                {/*    </Navbar.Collapse>*/}
                </div>
            </nav>
            {props.children}
        </>
    );
};
